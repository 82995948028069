/* eslint-disable */
let BASE;
let MEDIA_BASE;
let HOST;
let HOSTNAME;
let HOSTREPORT;
let HOSTREPORTNAME;
let HOSTFINANCE;
let HOSTFINANCENAME;
let PORT;
let WEBSOCKETBASE;
let BASEREPORT;
let BASEFINANCE;
let BUCKETNAME;
const PROTO = "http";
const PROTO_HTTPS = "https";
const WS = "ws";
const WSS = "wss";

if (process.env.REACT_APP_UI_ENV === "server") {
  HOST = "marketing.letseduvate.com";
  HOSTFINANCE = "orchids.finance.letseduvate.com";
  HOSTREPORT = "marketing-reports.letseduvate.com";
  HOSTNAME = `${HOST}/qbox`;
  HOSTREPORTNAME = `${HOSTREPORT}/qbox/reports`;
  HOSTFINANCENAME = `${HOSTFINANCE}/qbox`;
  BASE = `${PROTO_HTTPS}://${HOSTNAME}`;
  BASEREPORT = `${PROTO_HTTPS}://${HOSTREPORTNAME}`;
  BASEFINANCE = `${PROTO_HTTPS}://${HOSTFINANCENAME}`;
  BUCKETNAME = "letseduvate-marketing";
  WEBSOCKETBASE = `${WSS}://${HOST}:8001`;
  MEDIA_BASE = BASE;
} else if (process.env.REACT_APP_UI_ENV === "dev") {
  HOST = "marketing.dev-gke.letseduvate.com";
  HOSTREPORT = "marketing-reports.dev-gke.letseduvate.com";
  HOSTFINANCE = "orchids-stage.finance.stage-vm.letseduvate.com";
  HOSTNAME = `${HOST}/qbox`;
  HOSTREPORTNAME = `${HOSTREPORT}/qbox/reports`;
  HOSTFINANCENAME = `${HOSTFINANCE}/qbox`;
  BASE = `${PROTO_HTTPS}://${HOSTNAME}`;
  BASEREPORT = `${PROTO_HTTPS}://${HOSTREPORTNAME}`;
  BASEFINANCE = `${PROTO_HTTPS}://${HOSTFINANCENAME}`;
  BUCKETNAME = "letseduvate-marketing-stage";
  WEBSOCKETBASE = `${WSS}://${HOST}:8001`;
  MEDIA_BASE = BASE;
} else if (process.env.REACT_APP_UI_ENV === "qa") {
  HOST = "marketing.qa.letseduvate.com";
  HOSTREPORT = "marketing-reports.qa.letseduvate.com";
  HOSTNAME = `${HOST}/qbox`;
  HOSTREPORTNAME = `${HOSTREPORT}/qbox/reports`;
  BASE = `${PROTO_HTTPS}://${HOSTNAME}`;
  BASEREPORT = `${PROTO_HTTPS}://${HOSTREPORTNAME}`;
  BUCKETNAME = "letseduvate-marketing-stage";
  WEBSOCKETBASE = `${WSS}://${HOST}:8001`;
  MEDIA_BASE = BASE;
} else if (process.env.REACT_APP_UI_ENV === "stage") {
  HOST = "marketing.stage-gke.letseduvate.com";
  HOSTREPORT = "marketing-reports-stage.stage-gke.letseduvate.com";
  HOSTFINANCE = "orchids-stage.finance.stage-vm.letseduvate.com";
  HOSTNAME = `${HOST}/qbox`;
  HOSTREPORTNAME = `${HOSTREPORT}/qbox/reports`;
  HOSTFINANCENAME = `${HOSTFINANCE}/qbox`;
  BASE = `${PROTO_HTTPS}://${HOSTNAME}`;
  BASEREPORT = `${PROTO_HTTPS}://${HOSTREPORTNAME}`;
  BASEFINANCE = `${PROTO_HTTPS}://${HOSTFINANCENAME}`;
  BUCKETNAME = "letseduvate-marketing-stage";
  WEBSOCKETBASE = `${WSS}://${HOST}:8001`;
  MEDIA_BASE = BASE;
} else {
  // HOSTNAME = 'localhost';
  // PORT = '8001';
  // BASE = `${PROTO}://${HOSTNAME}:${PORT}/qbox`;
  // BASEREPORT =  `${PROTO}://${HOSTNAME}:${PORT}/qbox/reports`;
  // WEBSOCKETBASE = `${WS}://${HOSTNAME}:${PORT}`;
  // BUCKETNAME = "letseduvate-marketing-stage";
  // MEDIA_BASE = BASE;
  HOST = "marketing.dev-gke.letseduvate.com";
  HOSTREPORT = "marketing-reports.dev-gke.letseduvate.com";
  HOSTFINANCE = "orchids-stage.finance.stage-vm.letseduvate.com";
  HOSTNAME = `${HOST}/qbox`;
  HOSTREPORTNAME = `${HOSTREPORT}/qbox/reports`;
  HOSTFINANCENAME = `${HOSTFINANCE}/qbox`;
  BASE = `${PROTO_HTTPS}://${HOSTNAME}`;
  BASEREPORT = `${PROTO_HTTPS}://${HOSTREPORTNAME}`;
  BASEFINANCE = `${PROTO_HTTPS}://${HOSTFINANCENAME}`;
  BUCKETNAME = "letseduvate-marketing-stage";
  WEBSOCKETBASE = `${WSS}://${HOST}:8001`;
  MEDIA_BASE = BASE;
}

const urls = {
  websocketUrl: `${WEBSOCKETBASE}/ws/notification`,
  sampleCsvUrl: `${PROTO_HTTPS}://storage.googleapis.com/${BUCKETNAME}/prod/shared-media/media`,
  base: `${BASE}`,
  login: `${BASE}/login/`,
  roles: `${BASE}/retrieve_roles/`,
  BASE,
  MEDIA_BASE,
  branches: `${BASE}/branch/`,
  grades: `${BASE}/ProductWiseGradeApi/`,
  registration: `${BASE}/registration/`,
  academicYear: `${BASE}/AcademicYear/`,
  contactSource: `${BASE}/ContactSource/`,
  leadFollowUp: `${BASE}/assignlead/`,
  roleWiseStatus: `${BASE}/retrieve_roles_wise_status/`,
  getUserList: `${BASE}/EmployeeRetrieve/`,
  addLead: `${BASE}/lead_registration/`,
  counsellorAddLead: `${BASE}/CounsellorLeadCreateAPIView/`,
  bdaAddLead: `${BASE}/BDALeadCreateAPIView/`,
  EnquiryUpdateFormData: `${BASE}/RetrieveUpdateLeadDetails/`,
  searchData: `${BASE}/leadenquiry/`,
  leadInfoFilter: `${BASE}/lead_info_filter/`,
  downloadSampleLeadFile: `${BASE}/download_sample_data/`,
  downloadSampleLeadFileCcm: `${BASE}/download_sample_data_lead_cold_calling/`,
  uploadLeadDetails: `${BASE}/uploadleadsdetails/`,
  bulkUploadLead: `${BASE}/bulk_upload_lead/`,
  FbLeadUploadlead: `${BASE}/FbLeadUploadxlx/`,
  agentList: `${BASE}/cca_details/`,
  agentNameList: `${BASE}/agent_list/`,
  assignLeadToCCA: `${BASE}/assign_lead_to_cca/`,
  reassignLeadToCCA: `${BASE}/reassign_lead/`,
  addContacts: `${BASE}/AddEventNameAPIView/`,
  contactBranch: `${BASE}/retrieve_rolewise_branches/`,
  retrieveTlDetails: `${BASE}/retrieve_tl_details/`,
  createPro: `${BASE}/createpro/`,
  counsellorList: `${BASE}/retrievecounsellors/`,
  assignLeadToCounsellor: `${BASE}/assigned_bdm_lead/`,
  assignDigitalCounsellorLead: `${BASE}/assigned_digital_counsellor_lead/`,
  assignedLeadToCounsellor: `${BASE}/assign_lead_to_counsellor/`,
  assignedLeadToDigitalCounsellor: `${BASE}/assign_lead_to_digital_counsellor/`,
  reAssignedLeadToDigitalCounsellor: `${BASE}/re_assign_lead_to_digital_counsellor/`,
  reAssignLeadToCounsellor: `${BASE}/ReAssignLeadTOCounsellorAPIView/`,
  counsellorFollowUpDetails: `${BASE}/counsellorfollowupdetails/`,
  profollowupdetails: `${BASE}/profollowupdetails/`,
  counsellorFollowUpCount: `${BASE}/CountCounsellorDetails/`,
  proFollowUpCount: `${BASE}/CountProDetails/`,
  counsellorFollowUpUpdateSave: `${BASE}/createcounsellorfollowup/`,
  razorpayFollowUpUpdateSave: `${BASE}/razorpay/CreateRazorpayFollowUpDetails/`,
  proFollowUpUpdateSave: `${BASE}/CreateProFollowUpDetails/`,
  counsellorFollowupEnquierStatus: `${BASE}/counsellorfollowupenquierstatus/`,
  retrieveStatusName: `${BASE}/retrieveenquirystatuscounsellor/`,
  leadDetails: `${BASE}/retrieve_lead_details/`,
  saveLeadDetails: `${BASE}/createfollowupdetails/`,
  managedLeads: `${BASE}/managed_lead/`,
  manageDupLeads: `${BASEREPORT}/DuplicateLeadCounsellorDataReport/`,
  retrieveCounsellors: `${BASE}/retrievecounsellors/`,
  shuffleCounsellors: `${BASE}/swapping_counselor_leads/`,
  swipeCounsellor: `${BASE}/CounsellorBranchChange/`,
  agentPerformanceReport: `${BASE}/ccm_agents_reports/`,
  agentPerformanceReportCSV: `${BASE}/agentperformanceexcel/`,
  updateIndividualUser: `${BASE}/EmployeeRetrieveUpdateLeadDetails/`,
  EmployeeData: `${BASE}/EmployeeinfofilterAPIView/`,
  ChangePassword: `${BASE}/changepassword/`,
  ResetPassword: `${BASE}/resetpassword/`,
  FrontOfficeDashboard: `${BASEREPORT}/FrontOfficeDashboard/`,
  FrontOfficeLeadInfo: `${BASEREPORT}/LeadInfoFilterBYList/`,
  status: `${BASE}/walkincompleted/`,
  statusUpdate: `${BASE}/leadstatusupdate/`,
  followupreport: `${BASE}/followupreport/`,
  bdmRoleWiseBranch: `${BASE}/bdm_role_wise_branch/`,
  branchWisePro: `${BASE}/branchwisepro/`,
  counsellorPerformance: `${BASEREPORT}/CounsellorPerformanceReport/`,
  ProContactSourceList: `${BASE}/ProContactSource/`,
  ProPerformanceReport: `${BASEREPORT}/properformancereport/`,
  Agentlist: `${BASE}/agent_list/`,
  AgentsReport: `${BASE}/AgentsPerformanceReport/`,
  registrationRoles: `${BASE}/reg_retrieve_roles/`,
  DuplicateLeads: `${BASEREPORT}/DuplicateLeadAPIView/`,
  proReportCSV: `${BASE}/downloadprocollectionreports/`,
  followUpReportCSV: `${BASE}/followupreportsheet/`,
  dupDataCSV: `${BASEREPORT}/duplicate_counsellor_follow_data/`,
  leadConversionReport: `${BASEREPORT}/LeadConversionReport/`,
  agentPerformance: `${BASEREPORT}/agentperformancereport/`,
  apartmenteventReport: `${BASEREPORT}/apartmenteventsreport/`,
  tieupPreSchoolsReport: `${BASEREPORT}/tieuppreschoolsreport/`,
  gateKeeperReport: `${BASE}/GateKeeperReport/`,
  branchWiseTieUpPreschool: `${BASE}/BranchWiseTieUpPreschool/`,
  concessionTypes: `${BASE}/concessiontypes/`,
  apartmentReportDownload: `${BASEREPORT}/apartment_report_csv/`,
  leadConversionFiles: `${BASEREPORT}/leadconversionfiles/`,
  exportGatekeeperReport: `${BASE}/exportgatekeeperreport/`,
  agentFollowReport: `${BASE}/agentfollowreport/`,
  exportFilesAgentPerformance: `${BASEREPORT}/exportfilesagentperformance/`,
  duplicateLeadsreports: `${BASEREPORT}/duplicateleadsreports/`,
  exportPreSchooltieupreports: `${BASEREPORT}/TieUpPreSchoolsReportCSV/`,
  digitalReports: `${BASE}/utmreports/`,
  proInactiveApiView: `${BASE}/proinactiveapiview/`,
  tldashboardProList: `${BASE}/tldashboardprolist/`,
  tlResetPassword: `${BASE}/tlresetpassword/`,
  bdmRoleList: `${BASE}/bdmrolelist/`,
  retrieveTlbranches: `${BASE}/retrievetlbranches/`,
  bdmDashboardTeamDetails: `${BASE}/bdmdashboardteamdetails/`,
  followUpreportsheet: `${BASE}/followupreportsheet/`,
  exportcounsellorPerformanceReport: `${BASEREPORT}/exportcounsellorperformancereport/`,
  exportproPerformanceReport: `${BASEREPORT}/exportproperformancereport/`,
  retrieveTl: `${BASE}/retrievetl/`,
  tlSwapping: `${BASE}/tlswapping/`,
  tlshuffleBranchwise: `${BASE}/tlshuffle_branchwise/`,
  retrieveTlPro: `${BASE}/retrieve_tl_pro/`,
  proSwapping: `${BASE}/proswapping/`,
  proShuffle: `${BASE}/proshuffle/`,
  branchWiseGrade: `${BASE}/BranchWiseGrade/`,
  applicantRegistration: `${BASE}/applicant_registration/`,
  ccaLeadsCreate: `${BASE}/cca_leads_create/`,
  eventsRetrieveView: `${BASE}/events_retrieve_view/`,
  eventsRetrieveUpdate: "/events_retrieve_update/",
  updateYear: `${BASE}/AcademicYearUpdateApiView/`,
  agentStatus: `${BASE}/AgentStatusAssignListAPIView/`,
  concessionApproveReport: `${BASE}/ConcessionApproveReport/`,
  exportConcessionApproveReport: `${BASE}/ExportConcessionApproveReport/`,
  onlineTestView: `${BASE}/OnlineTestView/`,
  onlineTestInstanceView: `${BASE}/OnlineTestInstanceView/`,
  //  frontOfficeReport: `${BASE}/front_office_report/`,
  frontOfficeReport: `${BASEREPORT}/ExportFrontOfficeReport/`,
  fastFollowupEnquiryStatus: `${BASE}/fast_followup_enquiry_status/`,
  fastFollowupFiltering: `${BASE}/fast_followup_filtering/`,
  enquiryStageUploadFiles: `${BASE}/enquiry_stage_upload_files/`,
  searchCcaLeads: `${BASE}/search_cca_leads/`,
  applicantResult: `${BASE}/applicant_results/`,
  tlList: `${BASE}/tl_list/`,
  contactSourcePro: `${BASE}/RetrieveProContactSourceApiView/`,
  counsellorRetrieveFollowUp: `${BASE}/RetrieveCounsellorFollowUpDetails/`,
  proRetrieveFollowUp: `${BASE}/RetrieveProFollowUpDetails/`,
  concessionApproveProcessApiView: `${BASE}/ConcessionApproveProcessApiView/`,
  convoxLogout: `${BASE}/ConvoxLogout/`,
  clickToCall: `${BASE}/ClickToCall/`,
  clickToCallKnowlarity: `${BASE}/ClickToCallKnowlarity/`,
  agentReady: `${BASE}/AgentReady/`,
  endCall: `${BASE}/EndCall/`,
  convoxHoldCall: `${BASE}/ConvoxHoldCall/`,
  convoxUnholdCall: `${BASE}/ConvoxUnholdCall/`,
  callDetailIncomingAndOutApi: `${BASE}/CallDetailIncomingAndOutApi/`,
  manageLeadReports: `${BASE}/manage_lead_reports/`,
  userListReport: `${BASEREPORT}/user_list_report/`,
  digitalMarketingShowLead: `${BASEREPORT}/DigitalMarketingShowLeadPIView/`,
  proLeadCollectReport: `${BASEREPORT}/ProLeadCollectReport/`,
  proLeadCollectAllDataReport: `${BASEREPORT}/ProLeadCollectAllDataReport/`,
  eventNameSearch: `${BASE}/event_name_search/`,
  exportProCollectorReport: `${BASEREPORT}/ExportProCollectorReport/`,
  exportProCollectorDetailReport: `${BASEREPORT}/ExportProCollectorDetailReport/`,
  ccmAgentsDayWiseLeadReport: `${BASE}/CCMAgentsDayWiseLeadReport/`,
  exportFilesDateWiseStatusReport: `${BASE}/ExportFilesDateWiseStatusReport/`,
  ccmAgentsDayWiseInterestedToWalkinLeadReport: `${BASE}/CCMAgentsDayWiseInterestedToWalkinLeadReport/`,
  exportFilesDateWiseInterestToWalkinReport: `${BASE}/ExportFilesDateWiseInterestToWalkinReport/`,
  duplicateDigitalMarketingShowLeadPIView: `${BASEREPORT}/DuplicateDigitalMarketingShowLeadPIView/`,
  exportDigitalDuplicateLeads: `${BASEREPORT}/export_digital_duplicate_leads/`,
  exportDigitalLeads: `${BASEREPORT}/export_digital_leads/`,
  foReportDataDetailReport: `${BASEREPORT}/fo_report_data_detail_report_excel/`,
  ccmAgentsDayWiseInterestedToWalkinSourceWiseLeadReport: `${BASE}/CCMAgentsDayWiseInterestedToWalkinSourceWiseLeadReport/`,
  exportFilesDateWiseInterestToWalkinSourceWiseReport: `${BASE}/ExportFilesDateWiseInterestToWalkinSourceWiseReport/`,
  agentPerformanceClickableReport: `${BASEREPORT}/AgentPerformanceClickableReport/`,
  agentPerformanceDetailedReport: `${BASEREPORT}/agent_performance_details_report/`,
  preSchoolTieUpDetailReport: `${BASEREPORT}/PreSchoolTieUpDetailReport/`,
  statusTitleList: `${BASE}/StatusTitleList/`,
  branchCrew: `${BASE}/BranchCrewAPIView/`,
  branchCrewDetail: `${BASE}/BranchCrewDetailAPIView/`,
  reAssignLeadToCcm: `${BASE}/ReAssignLeadToCcmAPIView/`,
  preschooldetailexcel: `${BASEREPORT}/pre_school_tie_up_data_detail_report_excel/`,
  leadHistoryReport: `${BASE}/LeadHistoryReport/`,
  leadHistoryReportExcel: `${BASE}/lead_history_report_excel/`,
  branchWiseCounsellor: `${BASE}/BranchWiseCounsellor/`,
  assignStudentInteraction: `${BASE}/AssignStudentInteraction/`,
  checkInterviewFree: `${BASE}/CheckInterviewFree`,
  fetchStudentInteractionDetail: `${BASE}/FetchStudentInteractionDetail`,
  reAssignLeadToBdm: `${BASE}/AssignLeadTOBdmFromCCmAPIView/`,
  zoomInteractionUrl: `${BASE}/ZoomInteractionUrl/`,
  studentInteractionFeedbackSubmit: `${BASE}/StudentInteractionFeedbackSubmit/`,
  fetchBranchRelatedCoordinate: `${BASEREPORT}/MapFetchBranchRelatedCoordinatebranch/`,
  fetchBranchRelatedCoordinateForHeatMap: `${BASEREPORT}/HeatMapBranchRelatedCoordinatebranch/`,
  sendFollowUpSMS: `${BASE}/send_follow_up_sms/`,
  fetchPRORelatedCoordinate: `${BASEREPORT}/fetch_pro_related_coordinate`,
  addQuetionApi: `${BASE}/add_question_api/`,
  addAnnouncement: `${BASE}/AddAnnouncement/`,
  cityList: `${BASE}/CityList/`,
  createQuestionPaper: `${BASE}/create_question_paper/`,
  retrieveQuestion: `${BASE}/retrieve_question/`,
  retrieveQuestionforsuperadmin: `${BASE}/RetrieveQuestionForSuperAdmin/`,
  proMapCoordinatesApi: `${BASEREPORT}/retrieve_pro_tracking_coordinate/`,
  showProResults: `${BASE}/ShowProResults/`,
  retrieveResult: `${BASE}/RetrieveResultResponse/`,
  sendQp: `${BASE}/AssignMcqToPro/`,
  cityWiseBranches: `${BASE}/LocationWiseBranch/`,
  getQuestionPapers: `${BASE}/RetrieveQuestionPaperSuperAdminApi/`,
  getWelcome2Orchids: `${BASE}/Welcome2OrchidsDataReport/`,
  exportWelcome2OrchidsReport: `${BASE}/ExportWelcome2OrchidsReport/`,
  manageLeadSearchApi: `${BASEREPORT}/SearchManageLeads/`,
  categoryListApi: `${BASE}/category_list/`,
  subCategoryListApi: `${BASE}/sub_category_list/`,
  uploadVideoApi: `${BASE}/upload_videos/`,
  questionTypeListApi: `${BASE}/questions_type/`,
  questionCategoryListApi: `${BASE}/questions_category/`,
  createQuestionApi: `${BASE}/create_and_retrieve_questions/`,
  updateQuestionApi: `${BASE}/`,
  convertFileIntos3LinkApi: `${BASE}/upload_file_to_aws/`,
  counsellorStatusListApi: `${BASE}/counsellor_follow_status/`,
  virtualLeadDataApi: `${BASE}/virtual_lead/`,
  createCounsellingApi: `${BASE}/add_virtual_meeting/`,
  getScheduleMeetingApi: `${BASE}/GetVirtualMeetingAPI/`,
  updateCounsellengApi: `${BASE}/`,
  searchCounsellingApi: `${BASE}/SearchVirtualCounselling/`,
  questionSubCategoryApi: `${BASE}/RetrieveQuestionSubCategory/`,
  employeeRoleApi: `${BASE}/RetrieveEmployeeRolev2/`,
  deteteQuestonPaper: `${BASE}/`,
  branchandRoleWiseProApi: `${BASE}/RetrieveRoleBranchWieEmployee/`,
  getQuestionPaperListApi: `${BASE}/retrieve_question_paper/`,
  questionsListApi: `${BASE}/retrieve_question/`,
  submitMcqTestApi: `${BASE}/save_mcq_test_response/`,
  downloadShowResultApi: `${BASE}/export_show_employee_results/`,
  checkLinkExpireForLaunchAPi: `${BASE}/CheckLinkExpire/`,
  completeMetingApi: `${BASE}/MeetingCompleted/`,
  remarkSubmitApi: `${BASE}/MeetingRemarks/`,
  getVirualReportsApi: `${BASEREPORT}/VirtualCounsellorReport/`,
  faqQuestionApi: `${BASE}/list_create_FAQs/`,
  fuqQuestionEditApi: `${BASE}/`,
  getCategories: `${BASE}/list_faq_category/`,
  getDashboardReportsApi: `${BASE}/BranchWiseLeadCountReport/`,
  updateFollowUpBranch: `${BASE}/UpdateLeadBranch/`,
  superAdminUpdateBranchApi: `${BASE}/branch_detail/`,
  updateSuperAdminUpdateBranchApi: `${BASE}`,
  addCounsellorDetails: `${BASE}/branch_counsellor_detail/`,
  editCounsellorDetails: `${BASE}/`,
  getDeleteLeadDetails: `${BASE}/SearchDeleteLeads/`,
  apiToDeleteLead: `${BASE}/`,
  saleskenCallApi: "https://dd.salesken.ai:8443/mis/tata/proxy/make_call",
  updateFollowUpProfileDetails: `${BASE}/UpdateLeadFollowUpDetail/`,
  getLeadTransferApi: `${BASE}/TransferLeadAPIView/`,
  countAgentDetails: `${BASE}/CountAgentDetails/`,
  agentFollowUpDetails: `${BASE}/AgentFollowUpDetails/`,
  retrieveAgentFollowUpDetails: `${BASE}/RetrieveAgentFollowUpDetails/`,
  CreateAgentFollowUpDetails: `${BASE}/CreateAgentFollowUpDetails/`,
  AssignCounsellor: `${BASE}/agent_assign_to_counsellor/`,
  getPrizeList: `${BASE}/RetrieveAdPrice/`,
  addPrizeApi: `${BASE}/AddAdPrice/`,
  editPrizeApi: `${BASE}/UpdateAdPrice/`,
  priceReportDownload: `${BASE}/ad_price_report/`,
  marketingListApi: `${BASE}/FieldTypeListApi/`,
  getBranchWiseCounsellorList: `${BASE}/BranchWiseCounsellor/`,
  getBranchWiseBDAList: `${BASE}/BranchWiseBDA/`,
  reAssignCounsellorApi: `${BASE}/ReassignCounsellorToCounsellor/`,
  reAssignBdaApi: `${BASE}/ReassignBDAToBDA/`,
  counsellorDashboardApi: `${BASE}/today_follow_up_details/`,
  getScheduledVCApi: `${BASE}/TodayVCMeetingAPI/`,
  getPendingLeadRecordsApi: `${BASE}/PendencyLeadCount/`,
  getProPendingLeadRecordsApi: `${BASE}/ProPendencyLeadCount/`,
  reportLeadHistoryDetail: `${BASEREPORT}/lead_history_detail_report/`,
  reportLeadCombineHistoryDetail: `${BASEREPORT}/lead_combine_history_report/`,
  reportStatusBasedLeadDetail: `${BASEREPORT}/status_based_lead_report/`,
  virtualCounsellorReportCsv: `${BASEREPORT}/virtual_counselling_report_excel/`,
  historyCountApi: `${BASEREPORT}/FollowUpHistoryCount/`,
  followUpHistoryReport: `${BASEREPORT}/FollowUpHistoryDetailReport/`,
  reportTodayCounsellorWise: `${BASEREPORT}/counsellor_wise_report/`,
  reassignCounsellorList: `${BASE}/reassign_counsellor_list/`,
  reassignDigitalCounsellorList: `${BASE}/reassign_digital_counsellor_list/`,
  activeCounsellorList: `${BASE}/active_counsellor_list/`,
  activeDigitalCounsellorList: `${BASE}/active_digital_counsellor_list/`,
  CheckAlternateNo: `${BASE}/CheckAlternateNo/`,
  branchWiseTodayReport: `${BASEREPORT}/branch_wise_today_report/`,
  todayCounsellorFollowUp: `${BASEREPORT}/today_counsellor_follow_up_history_count/`,
  todayCounsellorFollowUpDetailReport: `${BASEREPORT}/today_counsellor_follow_up_detail_report/`,
  getWeekTargetListAPi: `${BASE}/CounsellorTargetSet/`,
  targetReportDownload: `${BASE}/export_counsellor_target_report/`,
  getBranchWiseSource: `${BASEREPORT}/SourceFrontOfficeDashboard/`,
  getContentSourse: `${BASE}/getContentSourse/`,
  counsellorStatusList: `${BASE}/CounsellorStatus/`,
  todayCounsellorFollowReport: `${BASEREPORT}/today_counsellor_follow_report/`,
  counsellorFollowHistoryReport: `${BASEREPORT}/counsellor_follow_history_report/`,
  ClickToCallV2: `${BASE}/CallNotification/`,
  getFeedback: `${BASE}/GetListFeedback/`,
  getPendingCallCount: `${BASE}/GetPendingCallingCount/`,
  startCall: `${BASE}/start_calling/`,
  stopCall: `${BASE}/stop_calling/`,
  getFollowUpCount: `${BASE}/FilterBYStatusLeadCount/`,
  addCall: `${BASE}/AddLeadForCalling/`,
  downloadDialerCSV: `${BASE}/export_dialer_report/`,
  getAgentTracking: `${BASE}/communication/v1/get_all_geo_tagging`,
  getAgentTrackingSearch: `${BASE}/communication/v1/global_search_tracking_logs/`,
  createRazorPayPayment: `${BASE}/razorpay/create_razorpay_payment/`,
  checkPreviousOrder: `${BASE}/razorpay/CheckPreviousOrder/`,
  getPaymentStatus: `${BASE}/razorpay/CheckStatusOfPaymentLink/`,
  cancelPaymentLink: `${BASE}/razorpay/CancelPaymentLink/`,
  exportPaymentReport: `${BASEREPORT}/razorpay/export_payment_report/`,
  exportPaymentCounsellorReport: `${BASE}/razorpay/export_payment_counsellor_report/`,
  getPaymentReport: `${BASEREPORT}/razorpay/GetListPayment/`,
  getPaymentCounsellorReport: `${BASE}/razorpay/GetListPaymentCounsellor/`,
  getVmpReport: `${BASEREPORT}/GetVmpReport/`,
  exportVmpReport: `${BASEREPORT}/export_vmp_report/`,
  sendQuiz: `${BASE}/SendQuiz/`,
  getSparleboxProduct: `${BASE}/sparklebox_details`,
  managedleadupdate: `${BASE}/managed_lead_update`,
  getCityList: `${BASE}/CityList/`,
  getBranchListByCity: `${BASE}/LocationWiseBranch/`,
  getZoneListByCity: `${BASE}/ManageLeadZoneFilter/`,
  getBranchlistByZone: `${BASE}/ManageLeadBranchFilter/`,
  getZoneCentralBranch: `${BASE}/ManageLeadCityZoneFilter/`,
  sendEmailToSales: `${BASE}/SendResultToSale/`,
  stockreceiveddetails: `${BASE}/stock_received_details`,
  collaterallist: `${BASE}/collateral_list`,
  getTablePROdataDashboard: `${BASEREPORT}/lead_collected_pro_status/`,
  getAllData: `${BASEREPORT}/lead_life_cycle_report/`,
  stockmovementreport: `${BASEREPORT}/stock_movement_report`,
  live_stock_details: `${BASEREPORT}/live_stock_details/`,
  getFieldBySource: `${BASE}/FieldWiseSource/`,
  exportlivestockreport: `${BASEREPORT}/export_livestock_report`,
  getcitywisebranches: `${BASE}/get_citywisebranches/`,
  exportstockdetailsreport: `${BASEREPORT}/export_stockdetails_report`,
  getcollateraldetails: `${BASEREPORT}/get_branchwise_livestock_details/`,
  branch_rolewise_user: `${BASE}/branch_rolewise_user`,
  get_state: `${BASE}/get_state`,
  updateLeadSource: `${BASE}/UpdateLeadSource/`,
  getSparkleBoxOrder: `${BASEREPORT}/GetSparkleBoxOrder/`,
  downloadSparkleboxOrder: `${BASEREPORT}/excel_sparkle_box_order/`,
  exportDailyReport: `${BASEREPORT}/export_campaign_report/`,
  exportWeeklyReport: `${BASEREPORT}/export_weekly_campaign_report/`,
  teamCreation: `${BASE}/team_creation/`,
  postEmiPayment: `${BASE}/razorpay/EmiPayment/`,
  enrollPlufoUser: `${BASE}/razorpay/EnrollPlufoUser/`,
  getPendingApprovePayment: `${BASEREPORT}/razorpay/GetPendingApprovePayment/`,
  approveDocumentPlufo: `${BASE}/razorpay/ApproveDocumentPlufo/`,
  adminEnrollPlufoUser: `${BASE}/razorpay/AdminEnrollPlufoUser/`,
  getQuizList: `${BASEREPORT}/GetQuiz/`,
  getLiveVc: `${BASE}/GetLiveVc/`,
  liveCheckLinkExpire: `${BASE}/LiveCheckLinkExpire/`,
  getStockMoveOut: `${BASE}/stock_moveout_get_all/`,
  postStockMoveout: `${BASE}/stock_moveout_details/`,
  getStockMoveOutDetails: `${BASE}/stock_moveout_details`,
  forgotPassword: `${BASE}/forget_password/`,
  getEmployee: `${BASE}/incentive/get_employee`,
  getDesignation: `${BASE}/incentive/get_designation`,
  getCity: `${BASE}/CityList`,
  addHierarchy: `${BASE}/incentive/create_hierarchy/`,
  exportbranchwiselivestockreport: `${BASEREPORT}/export_branchwise_livestock_report`,
  getHomeSchedule: `${BASE}/GetHomeSchedule/`,
  assignLeadToBda: `${BASE}/AssignLeadToBdaAPIView/`,
  getbdaList: `${BASE}/retrieve_bda/`,
  getDesignationist: `${BASE}/incentive/designation_list/`,
  exportQuizPurchaseReport: `${BASEREPORT}/export_quiz_purchase_report/`,
  exportAdmissionReport: `${BASEREPORT}/exportAdmissionRecord/`,
  getEmployees: `${BASE}/incentive/employees/`,
  createEmployeesTeam: `${BASE}/incentive/employeeshierachy/`,
  getImageDetails: `${BASE}/pro_branch/`,
  getCreatedEmployeesList: `${BASE}/incentive/getemployeeshierachy/`,
  exportSlashReport: `${BASEREPORT}/export_slash_report/`,
  designationAddUpdate: `${BASE}/incentive/designation_add_update/`,
  retrieveRoleWiseEmployee: `${BASE}/RetrieveRoleWieEmployee/`,
  managerBasedOnDesignation: `${BASE}/incentive/BasedOnDesignation/`,
  employeeHierarchyApi: `${BASE}/incentive/EmployeeHierarchyAPI/`,
  getEmployeeHierarchyList: `${BASE}/incentive/EmployeeHierarchyList`,
  order_management: `${BASE}/order_management/order_product`,
  getProducts: `${BASE}/razorpay/get_product_details`,
  proImageUpdate: `${BASE}/proimageupdate`,
  designationIncentiveAddUpdate: `${BASE}/incentive/DesignationIncentiveAddUpdate/`,
  employeeIndiviualIncentiveAddUpdate: `${BASE}/incentive/EmployeeIndiviualIncentiveAddUpdate/`,
  proAttendenceList: `${BASEREPORT}/pro_attendance_list/`,
  exportProAttendanceReport: `${BASEREPORT}/export_pro_attendance_report/`,
  postPaymentRemarks: `${BASE}/razorpay/payment_history/`,
  getFbAccountDetails: `${BASE}/fbaccoundetalis/`,
  getFbCostListApi: `${BASE}/GetFacebookAdsCost/`,
  getTaggingList: `${BASE}/tagging_list`,
  tagging: `${BASE}/tagging/`,
  getFollowUpCountData: `${BASEREPORT}/follow_up_count/`,
  sendWhatsAppMessage: `${BASE}/whatsapp_notification/`,
  reassignBdaList: `${BASE}/reassign_bda_list/`,
  reassignLeadToBda: `${BASE}/ReAssignLeadTOBdaAPIView/`,
  getOrderDetails: `${BASE}/razorpay/GetOrderDetail`,
  updateOrderDetails: `${BASE}/razorpay/UpdateOrderDetail/`,
  getEnrollProcessStatus: `${BASE}/GetEnrollProcessStatus/`,
  getOrderRemarksHistory: `${BASE}/razorpay/GetOrderRemarksHistory/`,
  checkPreviousOrderInstallment: `${BASE}/razorpay/CheckPreviousOrderInstallment/`,
  createEmiInstallmentRazorpayPayment: `${BASE}/razorpay/CreateEmiInstallmentRazorpayPayment/`,
  exportPendingEmiApproval: `${BASEREPORT}/razorpay/export_payment_report_emi_approval/`,
  createNewChild: `${BASE}/create_new_child/`,
  getChildDetail: `${BASE}/GetChildDetail/`,
  homeCounselling: `${BASEREPORT}/HomeCounsellingDashboard/`,
  homeCounsellingFilterList: `${BASEREPORT}/HomeCounsellingLeadFilterBYList/`,
  changeOrderDetail: `${BASE}/razorpay/ChangeOrderDetail/`,
  getRenewalData: `${BASEREPORT}/renewal_report/`,
  homeCounsellingReportCsv: `${BASEREPORT}/home_counselling_report/`,
  homeCounsellingDetailReportCsv: `${BASEREPORT}/hc_report_data_detail_report_excel/`,
  checkAdmissionRecord: `${BASE}/check_admission_record/`,
  uploadToS3File: `${BASE}/UpdateS3File/`,
  leadNoteList: `${BASE}/lead_notes_list/`,
  leadnotesDelete: `${BASE}/lead_notes_delete/`,
  leadTask: `${BASE}/lead-task-api/`,
  exportLeadTaskReport: `${BASEREPORT}/export_leadtask_report/`,
  todayLeadTask: `${BASE}/today-lead-task/`,
  leadBySource: `${BASE}/lead_by_source/`,
  leadStatusCount: `${BASE}/lead_status_count/`,
  leadDashboardData: `${BASE}/lead_dashboard_data/`,
  todayBdaFollowUp: `${BASEREPORT}/today_bda_follow_up_history_count/`,
  todayBdaFollowUpDetailReport: `${BASEREPORT}/today_bda_follow_up_detail_report/`,
  todayBdaFollowReport: `${BASEREPORT}/today_bda_follow_report/`,
  leadGraphData: `${BASE}/lead_graph_data/`,
  leadEngageData: `${BASE}/lead_engage_data/`,
  getKitDetails: `${BASE}/kit-details/`,
  exportKitDetails: `${BASE}/export-datewise-kit-report/`,
  todayDigitalCounsellorFollowUp: `${BASEREPORT}/today_digital_counsellor_follow_up_history_count/`,
  todayDigitalCounsellorFollowUpDetailReport: `${BASEREPORT}/today_digital_counsellor_follow_up_detail_report/`,
  todayDigitalCounsellorFollowReport: `${BASEREPORT}/today_digital_counsellor_follow_report/`,
  reAssignDigitalCounsellorApi: `${BASE}/ReassignDigitalCounsellorToDigitalCounsellor/`,
  getBranchWiseDigitalCounsellorList: `${BASE}/BranchWiseDigitalCounsellor/`,
  exportAttendenceReport: `${BASEREPORT}/export_attendance_report/`,
  exportProSystemReport: `${BASEREPORT}/export_pro_system_tracking_report/`,
  branchMapProTracking: `${BASEREPORT}/retrieve_branch_pro_tracking_coordinate/`,
  calldurationDetails: `${BASE}/call_duration_details/`,
  getSchoolList: `${BASE}/school_list/`,
  addContactSource: `${BASE}/AddContactSourceAPIView/`,
  updateChildDetails: `${BASE}/update_child_detail/`,
  incentiveCalculation: `${BASE}/incentive/incentive_calculation_report/`,
  counsellorStatusWiseCount: `${BASEREPORT}/CounsellorStatusWiseReport/`,
  hcSourceReport: `${BASEREPORT}/hc-source-report/`,
  getProStatus: `${BASE}/ProStatus/`,
  proWeekOffCount: `${BASEREPORT}/pro_weekoff_count`,
  LeadRingingNotPickReport: `${BASEREPORT}/LeadRingingNotPickReport/`,
  plufoSubjectList: `${BASE}/plufo-subject-list`,
  testProCoordinates: `${BASE}/TestProTrackingCoordinate`,
  attendanceAndSelfie: `${BASEREPORT}/ProSystemTracking/`,
  branchWiseQRCode: `${BASE}/BranchWiseQrCode`,
  folloeUpCountReport: `${BASEREPORT}/follow_up_count_report/`,
  leadSearchByQrCodeAPI: `${BASE}/LeadSearchByQrCodeAPIView/`,
  updateLeadDetailsFromQrCode: `${BASE}/UpdateLeadDetailsFromQrCode/`,
  incentiveCalculationReport: `${BASEREPORT}/IncentiveCalulcationReport/`,
  leadPromoApiView: `${BASE}/LeadPromoApiView/`,
  applicationQrCode: `${BASE}/ApplicationQrCode/`,
  incentiveRoleList: `${BASE}/RetrieveIncentiveRolev2/`,
  sourceDetails: `${BASE}/source-details/`,
  generatePaymentReciept: `${BASE}/razorpay/GeneratePaymentReciept/`,
  addBranch: `${BASE}/add_branch/`,
  ContactSourceQrListAPIView: `${BASE}/ContactSourceQrListAPIView/`,
  routeByBde: `${BASE}/bde-routes/`,
  assignRoutesCheck: `${BASE}/assign-routes-check/`,
  placeByRoute: `${BASE}/places-by-routes/`,
  routeCategoryList: `${BASE}/all-category/`,
  routeUpdateSequence: `${BASE}/update-sequence/`,
  updateloanApprove: `${BASE}/razorpay/UpdateloanApprove/`,
  installmentAddUpdate: `${BASE}/razorpay/InstallmentAddUpdate/`,
  todayBdaFollowUpReportDownload: `${BASEREPORT}/today_bda_follow_up_history_count_report/`,
  todayCounsellorFollowUpReportDownload: `${BASEREPORT}/today_counsellor_follow_up_history_count_report/`,
  todayDigitalCounsellorFollowUpReportDownload: `${BASEREPORT}/today_digital_counsellor_follow_up_history_count_report/`,
  admissionApplicationQr: `${BASEREPORT}/QRApplicationCount/`,
  admissionApplicationQrReport: `${BASEREPORT}/QRApplicationCountReport/`,
  admissionApplicationQrReportLink: `${BASEREPORT}/QRApplicationCountDetails/`,
  admissionApplicationQrReportLinkReport: `${BASEREPORT}/QRApplicationCountDetailsReport/`,
  vmpLeadDetails: `${BASEREPORT}/vmp_lead_details`,
  exportVmpLeadDetails: `${BASEREPORT}/export-vmp-lead-details`,
  changePasswordExp: `${BASE}/change_password/`,
  SourceDetailsReport: `${BASEREPORT}/SourceDetailsReport/`,
  checkPasswordDate: `${BASE}/check_password_date/`,
  RemoveAccessAgent: `${BASE}/RemoveAccessAgent/`,
  WhatsAppNotification: `${BASE}/whatsapp_branch_notification/`,
  generateForgotPasswordOtp: `${BASE}/otp_password/`,
  AssignedLeadAPIView: `${BASEREPORT}/AssignedLeadAPIView/`,
  getFollowNotification: `${BASEREPORT}/GetFollowNotification/`,
  VmpBranchWise: `${BASEREPORT}/GetVmpBranchReport`,
  VmpBranchWiseLead: `${BASEREPORT}/vmp_lead_branch_details`,
  VmpBranchWiseLeadCsv: `${BASEREPORT}/vmp_lead_branch_csv_report`,
  getSchoolListAuth: `${BASE}/school_list_view/`,
  DormantStatusList: `${BASE}/DormantStatusList/`,
  DormantCountReport: `${BASEREPORT}/DormantCountReport/`,
  DormantDetailReport: `${BASEREPORT}/DormantDetailReport/`,
  DormantLeadByCitySourceBranch: `${BASE}/DormantLeadByCitySourceBranch/`,
  DormantLeadByDateWise: `${BASE}/DormantLeadByDateWise/`,
  CheckIn: `${BASE}/check_in/`,
  CheckOut: `${BASE}/check_out/`,
  IncentiveReleaseReport: `${BASEREPORT}/IncentiveReleaseReport/`,
  UpdateIncentiveScript: `${BASE}/UpdateIncentiveScript/`,
  DeleteAdmissionApplication: `${BASE}/DeleteAdmissionApplication/`,
  AddLeadSourceAPIView: `${BASE}/AddLeadSourceAPIView/`,
  UpdateAdmissionApplicationByAdmin: `${BASE}/UpdateAdmissionApplicationByAdmin/`,
  DeleteAdmissionDetails: `${BASEREPORT}/DeleteAdmissionDetails/`,
  WeekOffAPIView: `${BASE}/WeekOffAPIView/`,
  getUserName: `${BASE}/get_username/`,
  TopCourseListAPIView: `${BASE}/order_management/TopCourseListAPIView/`,
  GetOrderProduct: `${BASE}/order_management/GetOrderProduct/`,
  UpdateLeadDetailsByParent: `${BASE}/UpdateLeadDetailsByParent/`,
  WalkinLeadReport: `${BASEREPORT}/WalkinLeadReport/`,
  walkinLeadReportCsv: `${BASEREPORT}/WalkinLeadReportCsv/`,
  WalkinSourceReport: `${BASEREPORT}/walkin_source_report/`,
  WalkinFilterByList: `${BASEREPORT}/WalkinFilterBYList/`,
  walkinreportDataCsv: `${BASEREPORT}/walkin_report_data_detail_report_excel/`,
  bulkUploadCollegeLead: `${BASE}/bulk_upload_college_lead/`,
  downloadSampleCsv: `${BASE}/download_sample_data_csv/`,
  EmailOtpVerifyPayout: `${BASE}/EmailOtpVerifyPayout/`,
  PayoutConfirmationData: `${BASE}/PayoutConfirmationData/`,
  CheckPayoutStatus: `${BASE}/CheckPayoutStatus/`,
  ProLeadCollectTimeBasedReport: `${BASEREPORT}/ProLeadCollectTimeBasedReport/`,
  ProLeadCollectTimeBasedReportExport: `${BASEREPORT}/ProLeadCollectTimeBasedReportExport/`,
  FollowUpStatus: `${BASE}/FollowUpStatus/`,
  UserWiseTimeBasedFollowUp: `${BASEREPORT}/UserWiseTimeBasedFollowUp/`,
  UserWiseTimeBasedFollowUpExport: `${BASEREPORT}/UserWiseTimeBasedFollowUpExport/`,
  PLufoRazorpayPaymentV2: `${BASE}/razorpay/PLufoRazorpayPaymentV2/`,
  razorpay: `${BASE}/razorpay/`,
  razorpayUpdateAndGetEnrollStudent: `${BASE}/razorpay/UpdateAndGetEnrollStudent/`,
  ProEventFilterReport: `${BASEREPORT}/ProEventFilterReport/`,
  ProEventFilterReportExport: `${BASEREPORT}/ProEventFilterReportExport/`,
  BdaCounsellorReport: `${BASEREPORT}/BdaCounsellorReport/`,
  BdaCounsellorReportExport: `${BASEREPORT}/BdaCounsellorReportExport/`,
  EnquiryFormLeadReport: `${BASEREPORT}/enquiry_form_lead_data/`,
  PLufoRazorpayEmiPaymentV2: `${BASE}/razorpay/PLufoRazorpayEmiPaymentV2/`,
  CheckAndUpdateAdmissionFinanceRecord: `${BASE}/CheckAndUpdateAdmissionFinanceRecord/`,
  UpiUploadBYExcel: `${BASE}/UpiUploadBYExcel/`,
  RemoveRecordFormError: `${BASE}/RemoveRecordFormError/`,
  UploadRecordingFile: `${BASE}/UploadRecordingFile/`,
  ProGreythrReportExport: `${BASEREPORT}/greythr/ProGreythrReportExport/`,
  BDAGreythrReportExport: `${BASEREPORT}/greythr/BDAGreythrReportExport/`,
  ExportAgentSourceWIseReport: `${BASE}/ExportAgentSourceWIseReport/`,
  ExportAgentBranchWIseReport: `${BASE}/ExportAgentBranchWIseReport/`,
  ExportAgentStatusWIseReport: `${BASE}/ExportAgentStatusWIseReport/`,
  checkOrderPaymentStatus: `${BASE}/razorpay/check_payment_order_status/`,
  BulkUploadUser: `${BASE}/bulk_upload_user/`,
  downloadSampleUserFile: `${BASE}/sample_data_bulk_user`,
  BulkUploadDeactivateUser: `${BASE}/bulk_upload_deactivate_user/`,
  DeactivateUser: `${BASE}/deactivate_user/`,
  ManagedLeadAPIView: `${BASEREPORT}/ManagedLeadAPIViewV3/`,
  ManageLeadReportCSV: `${BASEREPORT}/ManageLeadReportCSV/`,
  changeProfilePssword: `${BASE}/profile_password_update/`,
  GetLeadDetail: `${BASE}/GetLeadDetail/`,
  HomeCounsellingAddonUser: `${BASE}/HomeCounsellingAddonUser/`,
  masterDataEvents: `${BASE}/master_data_event/`,
  getMasterEvents: `${BASE}/master_event/`,
  employeeProfileList: `${BASE}/employee_profile_list/`,
  employeeFeedbackHistory: `${BASE}/employee_feedback_history/`,
  AddReactionLead: `${BASE}/AddReactionLead/`,
  BranchWiseWeekOff: `${BASEREPORT}/branchwise_weekoff/`,
  WeekOffDetailsApi: `${BASEREPORT}/weekoff_details_api/`,
  EnquiryListRequest: `${BASE}/enquiry_list/`,
  RequestActivity: `${BASE}/request-activity/`,
  AssignEventList: `${BASE}/assign_event_list/`,
  MultipleUserProfile: `${BASE}/multiple_events_user_profile/`,
  EventStatusAnalysis: `${BASE}/event_status_analysis/`,
  FetchBranchUser: `${BASE}/fetch_branch_user/`,
  FetchEvents: `${BASE}/fetch_events/`,
  LeadApplicationFilter: `${BASE}/lead_application_filter/`,
  EventMonitoringTimeline: `${BASE}/events-monitoring/`,
  LiveLocationMonitoring: `${BASE}/live-location-monitoring/`,
  StockLocationList: `${BASE}/stock_location_list/`,
  CreateStock: `${BASE}/create_stock/`,
  SearchStockList: `${BASE}/stock_list/`,
  StockDetails: `${BASE}/stock-details/`,
  UnitTypeList: `${BASE}/units-list/`,
  VendorDetails: `${BASE}/vendor-list/`,
  locationTypeList: `${BASE}/location_type_list/`,
  allStocks: `${BASE}/all-stocks/`,
  StockLocationWiseBranch: `${BASE}/stock_location_wise_branch`,
  StockOutList: `${BASE}/stockout-list/`,
  PostStockOutList: `${BASE}/stockout/`,
  UpdateStocks: `${BASE}/update-stock/`,
  pipTagging: `${BASE}/employee_piptag/`,
  pipHistory: `${BASE}/employee_piptag_history/`,
  goalReport: `${BASEREPORT}/goal_management/goal_detail_report/`,
  goalCreation: `${BASE}/goal_management/goal-creation/`,
  userGoals: `${BASE}/goal_management/user-goals/`,
  userchildGoals: `${BASE}/goal_management/user-childs-goals/`,
  routeAssign: `${BASE}/route/`,
  stockCategory: `${BASE}/list_category/`,
  createStockMasterData: `${BASE}/create_stock_master_data/`,
  getStockMasterData: `${BASE}/list_stock_master_data/`,
  stockListByMasterData: `${BASE}/stock_list_by_master_data/`,
  stockUpdateMasterData: `${BASE}/updatemaster_data/`,
  fetchProField: `${BASE}/fetch_pro_field/`,
  fetchProHotspotList: `${BASE}/fetch_pro_hotspot_list/`,
  fetchRouteList: `${BASE}/fetch_route_list/`,
  fetchProHotspotDetail: `${BASE}/fetch_pro_hotspot_details/`,
  stockTransitList: `${BASE}/transit_list/`,
  stockTransitStatusUpdate: `${BASE}/transit_status_update/`,
  userListBranchRole: `${BASE}/goal_management/userlist-branch-role/`,
  stockConsumptionReport: `${BASEREPORT}/stock_consumption_report/`,
  incentiveConfigurations: `${BASE}/goal_management/incentive-management/`,
  goalsListing: `${BASE}/goal_management/goals-listing/`,
  incentiveListing: `${BASE}/goal_management/incentive-listing/`,
  blackListing: `${BASE}/goal_management/black-listing/`,
  blackListingManagement: `${BASE}/goal_management/blacklisting-management/`,
  userIncentiveListing: `${BASEREPORT}/goal_management/user-incentive-listing/`,
  userIncentiveDetail: `${BASEREPORT}/goal_management/user-incentive-detail/`,
  userBlacklistedIncentiveDetail: `${BASEREPORT}/goal_management/user-blacklisted-incentive-detail/`,
  incentiveAmountCount: `${BASEREPORT}/goal_management/incentive-amount-count/`,
  incentiveNonEligibleAmountCount: `${BASEREPORT}/goal_management/non-eligible-incentive-amount-count/`,
  incentiveReportDownload: `${BASEREPORT}/goal_management/incentive_report_csv/`,
  incentiveTransactionReport: `${BASEREPORT}/goal_management/incentive_transaction_report/`,
  userIncentiveRecords: `${BASEREPORT}/goal_management/user_incentive_records/`,
  userIncentiveAmount: `${BASEREPORT}/goal_management/user_incentive_amount/`,
  paymentUpdate: `${BASE}/goal_management/payment-update/`,
  stockInReport: `${BASEREPORT}/stock_in_report/`,
  stockOutReport: `${BASEREPORT}/stock_out_report/`,
  bdaDashboardGraph: `${BASEREPORT}/LeadCountforBdaDashboard/`,
  proFieldPerformanceReport: `${BASEREPORT}/profield_report/`,
  proEventPerformanceReport: `${BASEREPORT}/proevent_report/`,
  bdaPerformanceReport: `${BASEREPORT}/bda_lead_performance_report/`,
  counsellorPerformanceReport: `${BASEREPORT}/counsellor_lead_performance_report/`,
  digitalFbPerformanceReport: `${BASEREPORT}/digital_fb_performance_report/`,
  fetchReportingManager: `${BASE}/fetch_reportingmanager/`,
  fetchReportingManagerErp: `${BASE}/fetch_reportingmanager_erp/`,
  proRouteMonitoring: `${BASEREPORT}/pro_route_monitoring/`,
  userHierarchy: `${BASE}/user_hierarchy/`,
  courierNameSuggestions: `${BASE}/courier_name_suggestions`,
  websiteEnquiryForm: `${BASE}/website_enquiry_form_api_v3/`,
  schoolLogoUrl: `${BASE}/central/school_details/`,
  stockListReport: `${BASEREPORT}/stocklist_report/`,
  b2bSchoolList: `${BASE}/b2b_school_list`,
  b2bPreSchoolBatchListing: `${BASEREPORT}/b2b_batch_listing/`,
  b2bPreSchoolMerge: `${BASE}/b2b_batch_merge_live/`,
  b2bPreSchoolBatchDetails: `${BASEREPORT}/b2b_batch_details/`,
  b2bPreSchoolBatchLeadDetails: `${BASEREPORT}/b2b_batch_leads_details/`,
  blacklistStudentList: `${BASE}/list-blacklisted-students/`,
  blacklistStudentHistory: `${BASE}/list-blacklist-history/`,
  bulkUploadProgress: `${BASE}/bulk_upload_progress/`,
  createEmailChannel: `${BASE}/digital-engagement/create-email-channel/`,
  createSMSChannel: `${BASE}/digital-engagement/create-sms-channel/`,
  createWhatsappChannel: `${BASE}/digital-engagement/create-whatsapp-channel/`,
  listChannelVariables: `${BASE}/digital-engagement/list-channel-variables/`,
  retrieveUpdateEmailCampaign: `${BASE}/digital-engagement/retrieve-update-email-campaign/`,
  retrieveUpdateSmsCampaign: `${BASE}/digital-engagement/retrieve-update-sms-campaign/`,
  retrieveUpdateWhatappCampaign: `${BASE}/digital-engagement/retrieve-update-whatsapp-campaign/`,
  listEmailCampaign: `${BASE}/digital-engagement/list-email-channels/`,
  listSmsCampaign: `${BASE}/digital-engagement/list-sms-channels/`,
  listWhatappCampaign: `${BASE}/digital-engagement/list-whatsapp-channels/`,
  testEmailCampaign: `${BASE}/digital-engagement/test-email-campaign/`,
  testSmsCampaign: `${BASE}/digital-engagement/test-sms-campaign/`,
  testWhatsappCampaign: `${BASE}/digital-engagement/test-whatsapp-campaign/`,
  listVariableGroup: `${BASE}/digital-engagement/list-channel-groups/`,
  digitalEngagementSampleExcel: `${BASE}/digital-engagement/get-sample-excel/`,
  digitalEngagementUploadExcel: `${BASE}/digital-engagement/upload-engagements/`,
  digitalEngagementAttachment: `${BASE}/digital-engagement/upload-attachments/`,
  digitalEngagementConfig: `${BASE}/digital-engagement/fetch-system-config/`,
  ssoLogin: `${BASE}/sso_login/`,
  hmacTokenCreate: `${BASE}/hmac_token/`,
  proPerformanceReport: `${BASEREPORT}/v3/pro_performance_report`,
  counsellorReport: `${BASEREPORT}/v3/counsellor_report`,
  newspaperProviderDropdown: `${BASEREPORT}/newspaper_provider_dropdown/`,
  newspaperManager: `${BASE}/news_paper_manager/`,
  targetAreaSearch: `${BASEREPORT}/news_paper_targetarea_dropdown/`,
  newsPaperListing: `${BASEREPORT}/newpaper_listing/`,
  preSchoolManager: `${BASE}/pre_school_manager/`,
  preSchoolListing: `${BASEREPORT}/pre_school_listing/`,
  callAuditListing: `${BASEREPORT}/call_audit_listing/`,
  callAuditManager: `${BASE}/call_audit_manager/`,
  branchWiseReport: `${BASEREPORT}/v3/branch_wise_report`,
  digitalMarketingReport: `${BASEREPORT}/v3/digital_marketing_report`,
  sendEnquiryForm: `${BASE}/list-create-counsellor-enquiry-form/`,
  updateCounsellorEnquiryForm: `${BASE}/update-counsellor-enquiry-form/`,
  branchGradeListAuthorised: `${BASE}/v3/ProductWiseGradeApi/`,
  branchGradeListUnAuthorised: `${BASE}/v2/ProductWiseGradeApi/`,
  leadInfoFilterv3: `${BASE}/v3/lead_info_filter/`,
  checkAlternateNoUnauthorized: `${BASE}/v2/CheckAlternateNo/`,
  updateOfflineCounsellorForm: `${BASE}/update-offline-counsellor-enquiry-form/`,
  listCounsellorEnquiryForm: `${BASE}/list-counsellor-enquiry-forms/`,
  notificationHandler: `${BASE}/notification_handler/`,
  bulkUploadIncentive: `${BASE}/incentive-bulk-upload/`,
  getIncentiveUploadList: `${BASE}/dwld-incentive-error-report/`,
  followUpNotificationV2: `${BASEREPORT}/GetFollowNotificationV2/`,
  callAuditAvgRating: `${BASEREPORT}/call_audit_avg_rating/`,
  rwaEvent: `${BASE}/rwa-event/`,
  rwaReport: `${BASEREPORT}/v3/rwa-report/`,
  callAuditReport: `${BASEREPORT}/call-audit-report/`,
  eventDetailsView: `${BASE}/events_details_view/`,
  digitalEngagementTriggers: `${BASE}/digital-engagement-triggers/`,
  callDurationChart: `${BASEREPORT}/hourly-call-duration-report/`,
  dialedCallChart: `${BASEREPORT}/hourly-dialed-call-count-report/`,
  connectedCallChart: `${BASEREPORT}/hourly-connected-call-count-report/`,
  dayCounsellingReport: `${BASEREPORT}/day-counselling-report/`,
  addProRemarks: `${BASE}/add-pro-remark/`,
  getChildFeeAmount: `${BASEFINANCE}/client-apiV1/get-registration-amount/`,
  childWebRegistration: `${BASEFINANCE}/client-apiV1/web-registration/`,
  counsellorHourlyDashboardReport: `${BASEREPORT}/counsellor-hourly-dashboard-report/`,
  assignLeadToCounsellorV2: `${BASE}/v2/assign_lead_to_counsellor/`,
  unAssignLeadsToCounsellor: `${BASE}/unassign-lead-from-counsellor/`,
  reAssignCounsellorListV2: `${BASE}/v2/reassign_counsellor_list/`,
  reAssignCounsellors: `${BASE}/reassign-counsellors/`,
  walkinDetailsFilterList: `${BASEREPORT}/v3/walkin-details-filter-list/`,
  leadWalkinDetails: `${BASEREPORT}/v3/lead-walkin-details/`,
  listDormantLeads: `${BASE}/list-dormant-leads/`,
  regenerateDormantLeads: `${BASE}/regenerate-dormant-leads/`,
  listRegenerationProcesses: `${BASE}/list-regeneration-processes/`,
  proPerformanceTracking: `${BASEREPORT}/v3/pro_performance_tracking/`,
  branchWiseReportLeadDetails: `${BASEREPORT}/v3/branch-lead-details/`,
  homeCounsellingDetailsFilterList: `${BASEREPORT}/v3/homecounselling-details-filter-list/`,
  updateLeadProStatus: `${BASE}/update_lead_pro_status/`,
  counsellorLeadFilter: `${BASEREPORT}/v3/counsellor_lead_filter/`,
  ProLeadCollectTimeBasedLeadReport: `${BASEREPORT}/ProLeadCollectTimeBasedLeadReport/`,
  ProPerformanceListFilter: `${BASEREPORT}/v3/ProPerformanceListFilter/`,
  DigitalMarketingFilterList: `${BASEREPORT}/v3/DigitalMarketingFilterList/`,
  ProAttendanceReportV3: `${BASEREPORT}/v3/pro_attendance_report/`,
  CreateCampaign: `${BASE}/digital-engagement/create-campaign/`,
  GetCampaign: `${BASE}/digital-engagement/campaign-details/`,
  UpdateCampaignDetails: `${BASE}/digital-engagement/update-campaign-details/`,
  UploadEngagementV2: `${BASE}/digital-engagement/v2/upload-engagements/`,
  ListChannelVariablesV2: `${BASE}/digital-engagement/v2/list-channel-variables/`,
  CampaignListV2: `${BASE}/digital-engagement/campaign-list/`,
  RemoveCampaign: `${BASE}/digital-engagement/remove-campaign/`,
  EngagementLeadDetails: `${BASE}/digital-engagement/engagements-lead-details/`,
  CampaignSearchList: `${BASE}/digital-engagement/campaign-details-list/`,
};
export default urls;
